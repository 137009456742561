import React from "react";
import { Link } from "gatsby";

export default function Terms() {
  return (
    <div className="privacy-terms">
      <p className="effective-date">Effective Date: November 16, 2022</p>
      <p>
        Thank you for your interest in using the online services operated by
        DeltaMath ("DeltaMath", "we" or "us"). These Terms of Service ("Terms"
        or "Agreement") govern your use of DeltaMath's online services (the
        "Services"). Please continue reading to learn about the terms by which
        you may use our Services.
      </p>
      <p>
        These Terms apply to all schools, school districts, or teachers
        (collectively referred to as "Schools") as well as students and parents,
        who use the Services; (collectively along with "Schools" referred to as
        “Users” or “you”).
      </p>
      <p>Any material change to these policies will be announced via a banner message displayed on this website.</p>
      <p>
        By accessing or using the Services, creating an account, or by otherwise
        affirmatively stating your desire to use the Services, you signify that
        you have read, understood, and agree to be bound by this Agreement and
        to the collection and use of your information as set forth in the
        DeltaMath <Link to="/privacy-policy">Privacy Policy</Link>, otherwise
        you may not use the Services. Because our Services change relatively
        often, the terms in this Agreement and our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> may change too. Upon
        making changes, we will update the “Effective Date” found at the top of
        this page. Your continued use of the Services after any changes
        constitutes your acceptance of the new terms.
      </p>

      <h2>DeltaMath's Service</h2>
      <p>
        DeltaMath is an online math practice and learning site. Students will
        register with a teacher code and complete assignments made by their
        teacher.
      </p>
      <p>
        As long as you are complying with all of the terms and conditions of
        this Agreement, DeltaMath gives you permission to access and use the
        Service. The Service is available for your personal, noncommercial use.
        We are constantly changing and improving our Services. We may add or
        remove functionalities or features, and we may suspend or stop a Service
        altogether, including, for example, if you do not comply with this
        Agreement or if we are investigating suspected misconduct. We may also
        stop providing Service to you, or add or create new limits to our
        Service or restrict your access to all or part of the Service at any
        time without notice or liability.
      </p>
      <p>
        In particular, individual teacher licenses, paid or unpaid, are intended
        for single-teacher use. We reserve the right to reach out to end-users
        and/or revoke access in cases where it appears more than one teacher is
        using an account without our prior consent.
      </p>
      <p>
        The right to access and use the Service is revoked in jurisdictions
        where it may be prohibited, if any.
      </p>
      <h2>Refund Policy</h2>
      <p>
        Refunds for site or individual teacher licenses are available within one
        month (30 days) of purchase. Full or partial refunds are not available
        after that time, and in no case will unused licenses roll over to the
        next school year. Contact orders@deltamath.com to request a refund.
      </p>
      <h2>Personal Information and Student Data</h2>
      <p>
        The U.S. Children’s Online Privacy and Protection Act (“COPPA”) requires
        that online service providers obtain verifiable parental consent before
        collecting personal information from children under 13. If you are a
        School providing the Service to children under 13 (whether in the U.S.
        or elsewhere), you represent and warrant that you have received consent
        from parents, or have the authority to provide consent on behalf of
        parents, for us to collect information from students before allowing
        children under 13 to access DeltaMath Services. We recommend that all
        Schools provide appropriate disclosures to students and parents
        regarding their use of service providers such as DeltaMath.
      </p>
      <p>
        When DeltaMath is used by a School for an educational purpose, DeltaMath
        may collect or have access to Student Data that is provided by the
        School or by the Student. “Student Data” is personal information that is
        directly related to an identifiable Student and may include “educational
        records” as defined by the Family Educational Rights and Privacy Act
        (“FERPA”), 20 U.S.C. § 1232(g).
      </p>
      <p>
        <b>Confidentiality</b>. DeltaMath agrees to treat Student Data as
        confidential and not to share it with third parties other than as
        described in these Terms, and in our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>
      <p>
        <b>Student Data Access</b>. You authorize DeltaMath to access or collect
        Student Data for the purpose of providing the Service. In the U.S.,
        DeltaMath shall collect and process Student Data as a School Official
        with a legitimate educational interest pursuant to FERPA 34 CFR Part
        99.31(a)(1).
      </p>
      <p>
        <b>Personal Information and Student Data Consents and Authority.</b>
        If you are School User, you represent and warrant that you have provided
        appropriate disclosures to your School and to parents regarding your
        sharing such Personal Information with DeltaMath. Both Parties agree to
        uphold their obligations under the Family Educational Rights and Privacy
        Act (“FERPA”), the Protection of Pupil Rights Amendment ("PPRA"), and
        the Children’s Online Privacy and Protection Act (“COPPA”) and
        applicable State laws relating to student data privacy. DeltaMath relies
        on each School to obtain and provide appropriate consent and
        disclosures, if necessary, for DeltaMath to collect any Student Data,
        including the collection of Student Data directly from students under
        13, as permitted under COPPA. You agree to comply with these Terms and
        all laws and regulations governing the protection of personal
        information, including children’s information, and the sharing of
        student education records.
      </p>
      <p>
        <b>Use of Student Data.</b> By submitting or providing us access to
        Student Data, you agree that DeltaMath may use the Student Data solely
        for the purposes of (i) providing the Service, (ii) improving and
        developing our Service, (iii) enforcing our rights under these Terms,
        and (iv) as permitted with the School’s or the User’s consent. DeltaMath
        shall not use Student Data to engage in targeted advertising.
      </p>
      <p>
        <b>Use of Anonymized Student Data.</b> You agree that we may collect and
        use data derived from Student Data for our own purposes, such as for
        product development, research analytics, and marketing our Service,
        provided that such data will be de-identified and/or aggregated to
        reasonably avoid identification of a specific individual.
      </p>
      <p>
        <b>Use of Personal Information for Marketing.</b> You agree that
        DeltaMath may provide information about new DeltaMath features and
        offerings to school or district administrative users and teachers from
        time to time, provided that such advertisements shall not be based on
        Student Data. For emphasis, and without limitation, DeltaMath shall
        never use Student Data to engage in targeted advertising, nor shall
        DeltaMath direct advertising to student users.
      </p>
      <p>
        <b>Third-Party Service Providers.</b> You acknowledge and agree that
        DeltaMath may provide access to Student Data to our employees and
        service providers, which have a legitimate need to access such
        information in order to provide their services to us. We and our
        employees, affiliates, service providers, or agents involved in the
        handling, transmittal, and processing of Student Data will be required
        to maintain the confidentiality of such data.
      </p>
      <p>
        <b>Student Data Retention and Deletion Requests.</b>
        DeltaMath retains Student Data, but for educational purposes only.
        Oftentimes students will want to refer back to their DeltaMath student
        accounts for college or other future courses. However, if a student does
        not utilize their account for a period of two years, their account and
        personally identifiable information will be deleted. Additionally,
        Schools may request that we delete Student Data in our possession at any
        time by providing such a request in writing. We shall respond to the
        deletion request as soon as possible, but in most instances within 45
        days, other than for data stored on backup tapes which shall be deleted
        in the ordinary course of business. A Parent seeking to modify, correct,
        or delete personal information in a Student Account that is connected to
        an active School account will be instructed to contact the School to
        discuss data deletion or modification. We are not required to delete
        data that has been derived from Student Data so long as it has been
        anonymized such that it does not reasonably identify an individual.
      </p>

      <h2>Use License</h2>
      <p>
        The Service and the DeltaMath Technology are intended solely for the
        personal, non-commercial use of our users and may only be used in
        accordance with this Agreement. “DeltaMath Technology” means all past,
        present and future content of the Service, including, all the software,
        hardware and technology used to provide the Service (including DeltaMath
        proprietary code and third-party software), user interfaces, materials
        displayed or performed on the Service, such as text, graphics, articles,
        graphs, photographs, images, illustrations and the design, structure,
        sequence and “look and feel” of the Services, and all other intellectual
        property. DeltaMath Technology is protected by copyright and other
        intellectual property laws. You are not allowed to use, store, copy,
        reproduce, modify, translate, publish, broadcast, transmit, distribute,
        perform, upload, create derivative works from, display, license, sell or
        otherwise exploit the DeltaMath Technology for any purposes other than
        as expressly permitted under this Agreement. You will not decompile,
        reverse engineer, or otherwise attempt to obtain the source code of any
        DeltaMath Technology.
      </p>
      <p>
        Using our Service does not give you ownership of any intellectual
        property rights in our Service or the DeltaMath Technology. You may not
        use content from our Services, unless you obtain permission from its
        owner or are otherwise permitted by law. Don’t remove, obscure, or alter
        any copyright or other legal notices displayed in or along with our
        Services.
      </p>
      <p>
        By using the Services, you agree not to do any of these things: (i)
        copy, distribute, or disclose any part of the Services in any medium,
        including but not limited to by any automated or non-automated
        “scraping”; (ii) use any automated system, including but not limited to
        “robots,” “spiders,” “offline readers,” etc., to access the Services in
        a manner that sends more request messages to the DeltaMath servers than
        a human can reasonably produce in the same period of time by using a
        conventional online web browser; (iii) transmit spam, chain letters, or
        other unsolicited email; (iv) attempt to interfere with, compromise the
        system integrity or security or decipher any transmissions to or from
        the servers running the Services; (v) take any action that imposes, or
        may impose an unreasonable or disproportionately large load on our
        infrastructure; (vi) transmit any malicious software agents through the
        Services; (vii) collect or harvest any third-party personally
        identifiable information, including account names or Student Data (as
        defined above), from the Services; (viii) use the Services for any
        commercial solicitation purposes; (ix) impersonate another person or
        otherwise misrepresenting your affiliation with a person or entity,
        conducting fraud, hiding or attempting to hide your identity; (x)
        interfere with the proper working of the Services; (xi) access any
        content on the Services through any technology or means other than those
        provided or authorized by the Services; or (xii) bypass the measures we
        use to prevent or restrict access to the Services, including but not
        limited to features that prevent or restrict use or copying of any
        content or enforce limitations on use of the Services or related
        content.
      </p>
      <h2>Privacy</h2>
      <p>
        Your privacy is extremely important to us, and we take great care to
        limit what we collect and how we use it, and to enable you to understand
        our policies. Please read our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>
        which explains how we treat your personal information and protect your
        privacy when you use our Service. By using our Service, you agree that
        DeltaMath can use such data in accordance with our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>

      <h2>Passwords and Security</h2>
      <p>
        You are responsible for maintaining the confidentiality of your
        DeltaMath password, and you are solely responsible for all activities
        that occur under your password. You agree to immediately notify
        DeltaMath of any unauthorized use of your password or any other breach
        of security related to the DeltaMath Services. DeltaMath may require you
        to alter your password if we believe that your password is no longer
        secure.
      </p>

      <h2>Links</h2>
      <p>
        DeltaMath has not reviewed all of the sites linked to its website and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by DeltaMath of the site. Use of
        any such linked website is at the user's own risk.
      </p>

      <h2>Modifications</h2>
      <p>
        DeltaMath may revise these terms of service for its website at any time
        without notice. By using this website you are agreeing to be bound by
        the then current version of these terms of service.
      </p>

      <h2>Disclaimer and Limitations of Liability</h2>
      <p>
        a. You agree that use of the DeltaMath services is at your sole risk.
        The DeltaMath services are provided on an “as is” and “as available”
        basis. DeltaMath expressly disclaims all warranties of any kind, whether
        express or implied, with respect to the DeltaMath services, including,
        but not limited to, the implied warranties of merchantability, fitness
        for a particular use or purpose, and non-infringement. You acknowledge
        that access to data and materials available through the DeltaMath
        services is not guaranteed and that DeltaMath will not be responsible to
        you for any loss of data or materials caused by the DeltaMath services
        or their unavailability. You understand and agree that any data,
        materials, services and/or information downloaded or otherwise obtained
        through the use of the DeltaMath services is done at your own discretion
        and risk and that you will be solely responsible for any damage arising
        therefrom.
      </p>
      <p>
        b. Under no circumstances will DeltaMath or its officers, employees,
        directors, shareholders, agents, or licensors be liable under any theory
        of liability (whether in contract, tort, statutory, or otherwise) for
        any damages whatsoever, including direct, indirect, incidental, special,
        consequential or exemplary damages, including but not limited to,
        damages for loss of money, revenues, profits, goodwill, use, data or
        other intangible losses (even if such parties were advised of, knew of
        or should have known of the possibility of such damages), resulting from
        your (or anyone using your account's) use of the DeltaMath services.
      </p>
      <p>
        c. If, notwithstanding these Terms, DeltaMath is found to be liable to
        you or any third party in connection with your use of the DeltaMath
        services, the total liability of DeltaMath and its officers, employees,
        directors, shareholders, agents, or licensors to you or to any third
        party is limited to one hundred U.S. Dollars ($100).
      </p>
      <p>
        d. <i>Exclusions And Limitations.</i> Some jurisdictions do not allow
        the exclusion of certain warranties or the limitation or exclusion of
        liability for certain damages. Accordingly, some of the above
        limitations and disclaimers may not apply to you. To the extent that
        DeltaMath may not, as a matter of applicable law, disclaim any implied
        warranty or limit its liabilities, the scope and duration of such
        warranty and the extent of DeltaMath's liability will be the minimum
        permitted under such applicable law.
      </p>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless DeltaMath and its
        officers, directors, employees, consultants and agents from and against
        any and all claims, liabilities, damages, losses, costs, expenses, fees
        (including reasonable attorneys' fees) that such parties may incur as a
        result of or arising from your (or anyone using your account's)
        violation of these Terms. DeltaMath reserves the right, at its own
        expense, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by you, and in such case, you agree
        to cooperate with DeltaMath's defense of such claim.
      </p>

      <h2>Termination</h2>
      <p>
        This Agreement shall remain in full force and effect while you use the
        Service. DeltaMath may suspend or terminate your access to the Service
        or your account at any time, for any reason (without cause or for your
        violation of any term of this Agreement), and without warning or notice,
        which may result in the loss of information associated with your
        account. Upon termination of your account, your right to use the Service
        will immediately cease. All provisions of this Agreement which, by their
        nature, should survive termination, shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, and limitations of liability.
      </p>

      <h2>Miscellaneous</h2>
      <p>
        The failure of either party to exercise, in any respect, any right
        provided for herein shall not be deemed a waiver of any further rights
        hereunder. DeltaMath shall not be liable for any failure to perform its
        obligations hereunder where such failure results from any cause beyond
        DeltaMath's reasonable control. If any provision of this Agreement is
        found to be unenforceable or invalid, that provision shall be limited or
        eliminated to the minimum extent necessary so that this Agreement shall
        otherwise remain in full force and effect and enforceable. This
        Agreement is not assignable, transferable or sublicensable by you except
        with DeltaMath's prior written consent. DeltaMath may transfer, assign
        or delegate this Agreement and its rights and obligations without
        restriction. This Agreement is governed by and construed in accordance
        with the laws of the State of New York without regard to the conflict of
        laws provisions thereof. To the extent any dispute arising from or
        relating to the subject matter of this Agreement is permitted to be
        brought in a court of law, such claim shall be subject to the exclusive
        jurisdiction of the state and federal courts located in New York County,
        New York, and for all purposes of this Agreement, you and DeltaMath
        consent to the exclusive jurisdiction and venue of such courts. Unless
        and solely to the extent that you or your Institution have a separate
        written agreement with DeltaMath that governs your use of the Service
        (in which case such agreement will control), we both agree that this
        Agreement is the complete and exclusive statement of the mutual
        understanding of the parties and supersedes and cancels all previous
        written and oral agreements, communications and other understandings
        relating to the subject matter of this Agreement, and that the Agreement
        may not be modified, except as otherwise provided herein. This Agreement
        and any subsequent versions of this Agreement posted to the Website will
        be deemed a writing signed by both parties. No agency, partnership,
        joint venture, or employment is created as a result of this Agreement
        and you do not have any authority of any kind to bind DeltaMath in any
        respect whatsoever.
      </p>
    </div>
  );
}
